import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`pol.is`}</h1>
    <p>{`The primary `}<a parentName="p" {...{
        "href": "/hosted",
        "title": "hosted"
      }}>{`hosted`}</a>{` instance of `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{`, maintained by `}<a parentName="p" {...{
        "href": "/about"
      }}>{`The Computational Democracy Project`}</a>{`, accessible at `}<a parentName="p" {...{
        "href": "https://pol.is/home"
      }}>{`https://pol.is/home`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      